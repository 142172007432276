#practice .practice-header {
  font-family: arial;
  font-size: 21px;
  color: rgb(77, 77, 77);
  font-weight: 500;
}

#practice .practice-areas-ul {
  line-height: 30px;
}

#practice .nested-ul {
  list-style-type: disc;
}
