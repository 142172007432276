#attorneys .attorney-imgs {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
  vertical-align: middle;
  border: 0;
}

#attorneys .bold {
  font-weight: bold;
  color: #2f2d2d;
  margin-bottom: 0px;
  line-height: 18px;
}

#attorneys .contact {
  margin-bottom: 0px;
}

#attorneys .bold-title {
  font-family: arial, "Source Sans Pro", sans-serif, "Open Sans", san serif,
    adobe-garamond-pro-1, adobe-garamond-pro-2, Garamond, Times, serif;
  font-size: 15px;
  line-height: 1.3em;
  display: inline-block;
  font-weight: bold;
}

#attorneys .name-link {
  text-decoration: none;
  color: #2f2d2d;
}

#attorneys .mail-link {
  transition-duration: 0.4s;
  color: #555;
  text-decoration: none;
  margin-top: 10px;
}

#attorneys .mail-link:hover {
  color: #fd150b;
}

#attorneys .read-more-link {
  color: #2f2d2d;
  font-size: 1em;
  font-weight: bold;
  font-family: "Open Sans", san serif, garamond;
}

#attorneys .read-more-link:hover {
  cursor: pointer;
  text-decoration: none;
}

#attorneys .content-type {
  display: inline;
}

#attorneys .attorneys-text {
  font-family: "Source Sans Pro", sans-serif, "Open Sans", san serif,
    adobe-garamond-pro-1, adobe-garamond-pro-2, Garamond, Times, serif;
  font-size: 16px;
  display: inline;
}

.attorneys-sidebar {
  margin-top: 20px;
}

@media (max-width: 992px) {
  .attorneys-sidebar {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .medium-screen-overwrite {
    flex: 0 0 100% !important;
    min-width: 100% !important;
  }
}

@media (max-width: 768px) {
  #attorneys {
    margin-left: 15px;
    margin-right: 15px;
  }

  #attorneys .attorney-img-text-wrapper-mobile {
    text-align: center;
    margin-bottom: 20px;
  }

  #attorneys .attorney-imgs {
    max-width: 100px;
  }

  #attorneys .mobile-view-attorneys {
    display: inline-block;
    margin-left: 15px;
    vertical-align: -25px;
  }
}
