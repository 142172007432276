/* Header */

/* BANNER */
#banner-text {
  color: #fff;
  display: inline-block;
  margin-right: 10px;
}

#banner-link {
  display: inline-block;
  text-decoration: none;
  color: #ea2021;
}

@media (max-width: 992px) {
  #banner-text {
    display: block;
    font-size: 13px;
    margin-bottom: 0px;
  }

  #banner-link {
    margin-bottom: 10px;
    font-size: 13px;
  }
}
/* BANNER */

#logo {
  float: left;
  width: 144px;
  height: 100%;
}

header #header {
  border-bottom: 1px solid #eee;
}

#logo img {
  max-width: 320px;
  top: 48%;
  position: relative;
  margin-top: -23px;
}

header#header .container {
  position: relative;
}

header#header .row-fluid {
  padding-top: 15px;
  padding-bottom: 15px;
}

header#header .row-fluid .span12 {
  height: 46px;
}

.flexslider {
  position: relative;
  z-index: 50;
  margin-bottom: 0;
}

#slider-fullwidth {
  border-bottom: 1px solid #ebebeb;
}

.nav_top {
  margin-top: -1px;
}

.nav_center {
  margin-left: 0px;
}

.nav_center nav {
  margin-top: 0px !important;
}

/* Menu */

header#header #navigation {
  float: right;
}

header#header.header_5 {
  border: none;
}

nav {
  float: left;
  width: 100%;
  margin-top: -14px;
}

nav .menu {
  float: left;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  position: relative;
  height: 31px;
  margin-top: 0;
}

nav .menu li {
  float: left;
  padding-top: 19px;
  padding-bottom: 4px;
  font-weight: normal;
  padding-left: 14px;
  padding-right: 14px;
  position: relative;
  height: 76px;
  border-top: 4px solid #fff;
  -moz-transition: all 0.8s ease;
}

nav .menu > li:hover {
  /*border-top:2px solid red;*/
}

nav .menu > li a {
  font-weight: 550;
  font-size: 15px;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

nav .menu li:hover {
  text-decoration: none;
  background: #e4e8e4;
  border-top: #4d4d4d;
  border-bottom: #4d4d4d;
} /*regular menu tab color hover over */ /*Was li a for text, changed to li*/

nav .menu li.current-menu-item a,
nav .menu li.current-menu-parent a {
}

nav .menu li.current-menu-item li a,
nav .menu li.current-menu-parent li a {
  font-weight: 500;
}

.fixed_header nav .menu li ul.non_mega_menu {
  top: 42px !important;
}

nav .menu li ul.sub-menu {
  list-style: none;

  margin: 0 !important;

  display: none;

  position: absolute;

  left: 0;

  top: 73px;

  z-index: 50000;

  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);

  width: 183px;

  padding: 0 !important;

  border-top: 3px solid #888;
}

nav .menu li:hover .sub-menu {
  display: block;
}

nav .menu li ul.sub-menu li {
  z-index: 50005;

  float: left;

  border-bottom: 1px solid #dedede;

  border-top: 1px solid #fff;

  text-align: left;

  margin: 0 !important;

  padding: 0 !important;

  height: auto;

  display: block;

  width: 100%;

  background: #f1f1f1;
}

nav .menu li ul.sub-menu li a {
  color: #4d4d4d;
  text-transform: lowercase;
  text-transform: initial;
  font-weight: 500 !important;
  float: left;
  line-height: 32px !important;
  padding: 3px 13px;
  font-size: 14px;
}

nav .menu li ul.sub-menu li:hover {
  opacity: 1;
  background: #e4e8e4;
} /*sub menu background color hover over */

nav .menu li ul.sub-menu li.current-menu-item {
  background: #f7f7f7;
}

nav .menu .sub-menu li.current-menu-item a {
  border: none !important;
}

nav .menu li .current-menu-item {
  background: #eee;
  color: #888;
}

nav .menu li ul.sub-menu li:hover a {
  color: #fd150b;
} /*sub menu hover over color */

nav .menu li li.current-menu-item > a {
  color: #555 !important;
}

nav .menu li ul.sub-menu li:last-child {
  border-bottom: none;
}

/* Custom  */
nav .menu li a {
  color: #4d4d4d;
  line-height: 33px;
}

nav .menu li a:hover {
  text-decoration: none;
}

.header_2 {
  width: 100%;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 3;
  background: #fff;
}

.header_2 .top_nav {
  height: 40px;
}

.header_2 .top_nav .small_widget {
  text-shadow: none;
  color: #777;
}

.header_2 .top_nav .widget_topnav {
  border-left: 1px solid #ddd !important;
}

.header_2 .small_widget {
  float: left;
  padding-top: 10px;
  padding-right: 11px;
  border-right: 1px solid #ddd;
  padding-left: 11px;
  padding-bottom: 10px;
}

.header_2 .small_widget a {
  color: #777;
}

.header_2 .small_widget i {
  padding-right: 7px;
}

.header_2 .top_nav .top_nav_sub {
  top: 11px;
  background: #fcfcfc;
  border: 1px solid #ddd;
  border-top: none;
}

.header_2 .small_widget:hover,
.header_2 .top_nav .active {
  box-shadow: inset 1px 0px 24px -1px #f1f1f1;

  -webkit-box-shadow: inset 1px 0px 24px -1px #f1f1f1;

  -moz-box-shadow: inset 1px 0px 24px -1px #f1f1f1;

  -o-box-shadow: inset 1px 0px 24px -1px #f1f1f1;

  border-right: 1px solid #ddd;
  height: 20px;
}

.isScrolledDown {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.isScrolledDown-xl {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.isScrolledDown-lg {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.isScrolledDown-md {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.isScrolledDown-sm {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.isScrolledDown-xl-hmenu {
  top: 70px !important;
}

.isScrolledDown-lg-hmenu {
  top: 64px !important;
}

.isScrolledDown-md-hmenu {
  top: 58px !important;
}

.isScrolledDown-sm-hmenu {
  top: 52px !important;
}

.isScrolledDown-hmenu {
  top: 46px !important;
}

.isScrolledDown-xl
  div.span12
  div#navigation.nav_top.pull-right
  nav
  ul#menu-menu.menu
  > li {
  height: 73px !important;
}

.isScrolledDown-lg
  div.span12
  div#navigation.nav_top.pull-right
  nav
  ul#menu-menu.menu
  > li {
  height: 70px !important;
}

.isScrolledDown-md
  div.span12
  div#navigation.nav_top.pull-right
  nav
  ul#menu-menu.menu
  > li {
  height: 67px !important;
}

.isScrolledDown-sm
  div.span12
  div#navigation.nav_top.pull-right
  nav
  ul#menu-menu.menu
  > li {
  height: 64px !important;
}

.isScrolledDown
  div.span12
  div#navigation.nav_top.pull-right
  nav
  ul#menu-menu.menu
  > li {
  height: 61px !important;
}

/* sub menu nav box full width*/
.isScrolledDown-lg
  div.span12
  div#navigation.nav_top.pull-right
  nav
  ul#menu-menu.menu
  li
  ul.sub-menu {
  top: 70px !important;
}

.isScrolledDown-md
  div.span12
  div#navigation.nav_top.pull-right
  nav
  ul#menu-menu.menu
  li
  ul.sub-menu {
  top: 67px !important;
}

.isScrolledDown-sm
  div.span12
  div#navigation.nav_top.pull-right
  nav
  ul#menu-menu.menu
  li
  ul.sub-menu {
  top: 64px !important;
}

.isScrolledDown
  div.span12
  div#navigation.nav_top.pull-right
  nav
  ul#menu-menu.menu
  li
  ul.sub-menu {
  top: 61px !important;
}

.top_nav {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;

  -webkit-transition-duration: 0.4s;

  -moz-transition-duration: 0.4s;

  -o-transition-duration: 0.4s;

  -ms-transition-duration: 0.4s;

  transition-duration: 0.4s;

  height: 30px;

  opacity: 1;
}

.shadow {
  display: block;
  background: url(../assets/images/big_shadow_full.png) center no-repeat;
  width: 100%;
  height: 81px;
  box-shadow: none !important;
  z-index: -1;
  position: absolute;
}

/* medium break */
@media (max-width: 992px) {
  .clf-logo {
    max-width: 200px !important;
  }
  #menu-menu li {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.slide-in {
  transform: translate(0) !important;
}

.slide-out {
  transform: translate(130%) !important;
}

#small-menu {
  display: none;
  margin-top: 25px;
  cursor: pointer;
}

/* hamburger menu */
.each-bar {
  width: 35px;
  height: 5px;
  background-color: black;
  margin: 6px 0;
}

/* small break */
@media (max-width: 768px) {
  /* hide the menu */
  #menu-menu {
    display: none;
  }
  /* show hamburger menu */
  #small-menu {
    display: block;
  }
}

/* small break */
@media (max-width: 576px) {
  #hamburger-menu {
    right: 0px !important;
  }
}
