#philip-cook-bio .philip-cook-bio-img {
  max-width: 120px;
  height: auto;
  vertical-align: middle;
  border: 0;
}

#philip-cook-bio .text {
  margin-bottom: 0px;
  line-height: 20px;
}

#philip-cook-bio .philip-cook-link {
  color: #555;
  transition-duration: 0.4s;
  margin-bottom: 0px;
  font-weight: 500;
  text-decoration: none;
}

#philip-cook-bio .philip-cook-link:hover {
  color: #fd150b;
  text-decoration: none;
}

#philip-cook-bio .header-text {
  font-family: "EB Garamond", Serif, "Source Sans Pro", sans-serif, "Open Sans",
    san serif, adobe-garamond-pro-1, adobe-garamond-pro-2, Garamond, Times,
    serif;
  font-size: 22px;
  line-height: 1.5em;
  font-weight: 500;
  color: rgb(77, 77, 77);
}

#philip-cook-bio .bold-title {
  font-weight: bold;
}

#philip-cook-bio .bio-spacer {
  line-height: 1em;
  margin-top: 10px;
  margin-bottom: 15px;
}

#philip-cook-bio .twitter {
  color: #4099ff;
}

#philip-cook-bio .linkedin {
  color: #4875b4;
}
#philip-cook-bio .vcard {
  color: #000;
}

#philip-cook-bio .cook-bio-sidebar {
  line-height: 1.3em;
  margin-bottom: 0px;
  font-size: 15px;
  display: inline;
}

#philip-cook-bio .super-lawyers-img {
  height: 90px;
  width: 120px;
  background-image: url("https://profiles.superlawyers.com/california-southern/los-angeles/lawyer/philip-e-cook/d9ad77cd-d037-4915-90cc-1f18e023a470.html");
  text-align: center;
  outline: none;
  border: none;
  margin-top: 20px;
}

#philip-cook-bio .super-lawyers-link {
  height: 20px;
  width: 120px;
  display: block;
  text-decoration: none;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 100%;
  padding-top: 56px;
  color: rgb(102, 102, 102);
  text-decoration: none;
  outline: none;
  border: none;
}

.philip-cook-bio-h2-links {
  margin-top: 5px;
  font-family: "EB Garamond", serif, garamond;
}
