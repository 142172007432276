#approach {
  font-family: "EB Garamond", Serif, "Source Sans Pro", sans-serif, "Open Sans",
    san serif, adobe-garamond-pro-1, adobe-garamond-pro-2, Garamond, Times,
    serif;
  font-size: 28px;
  line-height: 1.5em;
}

#approach .approach-title {
  font-family: "EB Garamond", Serif, "Source Sans Pro", sans-serif, "Open Sans",
    san serif, adobe-garamond-pro-1, adobe-garamond-pro-2, Garamond, Times,
    serif;
  color: #4d4d4d;
  font-size: 28px;
  font-weight: 500;
}

#approach .approach-subtitle {
  font-style: italic;
  font-family: arial, "EB Garamond", Serif, "Source Sans Pro", sans-serif,
    "Open Sans", san serif, adobe-garamond-pro-1, adobe-garamond-pro-2, Garamond,
    Times, serif;
  font-size: 22px;
  font-weight: 500;
}

#approach .approach-header {
  font-family: arial, "EB Garamond", Serif, "Source Sans Pro", sans-serif,
    "Open Sans", san serif, adobe-garamond-pro-1, adobe-garamond-pro-2, Garamond,
    Times, serif;
  font-size: 22px;
}

#approach .approach-stars {
  color: rgb(47, 45, 45);
  text-align: center;
}

#approach .star-spacer {
  padding: 0px 30px;
}

#approach .stars {
  font-size: 30px !important;
}

#approach .philipcook-signature {
  max-width: 160px;
  max-height: 220px;
}
