.tos-pp-header {
  font-size: 30px;
  font-family: "Open Sans", garamond;
  font-weight: 500;
  color: #4d4d4d;
}

.tos-pp-subtitle {
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.tos-pp-normal {
  font-family: "Open Sans", San serif, garamond, gotham, Arial, Helvetica,
    sans-serif;
  line-height: 1.5em;
  font-size: 1em;
  color: #2f2d2d;
}
