#insurance-experience h2 {
  font-family: "EB Garamond", Serif, "Source Sans Pro", sans-serif, "Open Sans",
    san serif, adobe-garamond-pro-1, adobe-garamond-pro-2, Garamond, Times,
    serif;
  font-size: 28px;
  line-height: 1.5em;
}

#insurance-experience hr {
  border-color: #fd150b;
  height: 2px;
  border-width: 1px 0 0;
  clear: both;
  margin: 10px 25px 10px 10px;
}

#insurance-experience .subtitle {
  font-family: arial, "Source Sans Pro", sans-serif, "Open Sans", san serif,
    adobe-garamond-pro-1, adobe-garamond-pro-2, Garamond, Times, serif;
  font-size: 16px;
  line-height: 1.3em;
  margin-bottom: 15px;
}

#insurance-experience ul {
  line-height: 1.5em;
}

#insurance-experience p {
  margin-bottom: 0px;
}

#insurance-experience .push-bottom {
  margin-bottom: 15px;
}

#insurance-experience .extra-links {
  margin-left: 30px;
}

#insurance-experience .bottom-links {
  margin-top: 5px;
  font-size: 21px;
  line-height: 20px;
  font-family: "EB Garamond", serif, garamond;
}
