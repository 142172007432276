#footer-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}

.page-footer {
  flex-shrink: 0;
}

#copyright {
  background: #23282e;
  color: #a0a0a0;
  font-size: 14px;
  padding: 20px 0px;
}

#copyright a {
  color: #a0a0a0;
}

#copyright .bar {
  color: #fd150b !important;
  font-size: 13px !important;
  padding: 0px 5px;
}

#copyright a:hover {
  cursor: pointer;
  text-decoration: none;
}
