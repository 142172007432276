#mediation .PhilipCookLogo {
  max-width: 160px;
  margin-top: 20px;
  margin-left: 40px;
  margin-bottom: 20px;
}

#mediation .cookMediationLogo {
  width: 230px;
  height: auto;
  padding: 2px 2px;
}

#mediation .PDFDownloadsClass {
  display: flex;
  color: #005eb8;
  max-height: 50px;
}

#mediation .PDFDownloadsClass p {
  padding: 5px;
  line-height: 20px;
  color: #005eb8;
  cursor: pointer;
}

#mediation .fa-file-pdf-o {
  color: red;
  padding: 10px;
}

#mediation .pdf-text-link-wrapper {
  display: inline;
}

#mediation .PDFDownloadsClass:hover {
  text-decoration: none;
  pointer: cursor;
}

#mediation .pdf-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#mediation .mobile-only {
  display: none;
}

#mediation .PDF-text-wrapper {
  display: block;
  text-align: left;
  margin: 0 auto;
  padding-left: 35px;
  min-width: 250px;
}

#mediation .img-wrapper {
  /* margin: 0 auto; */
}

#mediation .mediation-ul {
  line-height: 26px;
  font-family: "Source Sans Pro", sans-serif, "Open Sans", san serif,
    adobe-garamond-pro-1, adobe-garamond-pro-2, Garamond, Times, serif;
}

@media (max-width: 1200px) {
  #mediation .PDF-text-wrapper {
    padding-left: 40px;
  }
}

@media (max-width: 992px) {
  .reset-space {
    padding-left: 95px;
  }
}

@media (max-width: 768px) {
  #mediation .pdf-wrapper {
    flex-direction: row;
    margin-bottom: 15px;
  }
  #mediation .img-wrapper {
    margin: 0 auto;
  }
  #mediation .PDF-text-wrapper {
    padding-left: 0px;
    align-self: center;
  }
  #mediation .cookMediationLogo {
    display: none;
  }
  .reset-space {
    padding-left: 15px;
    padding-right: 15px;
  }

  #mediation .mobile-only {
    display: block;
  }
  #mediation .mobile-mediation-img {
    margin: 0 auto;
    display: block;
    width: 280px;
    margin-bottom: 15px;
  }
}

@media (max-width: 576px) {
  #mediation .PDF-text-wrapper {
    margin-left: 20px;
  }
}
