.redline {
  border-color: #900600 !important; /* COMEBACK */
  height: 2px;
  border-width: 1px 0 0;
  clear: both;
  margin: 10px 25px 10px 0px;
}

#home-body h2 {
  font-family: "EB Garamond, serif; garamond;";
  font-size: 21px;
  text-align: center;
}

#home-body p {
  font-family: "Source Sans Pro", sans-serif, "Open Sans", san serif,
    adobe-garamond-pro-1, adobe-garamond-pro-2, Garamond, Times, serif;
  font-size: 16px;
  line-height: 1.5em;
}

.each-section-wrapper {
  max-width: 400px;
  padding: 25px;
  margin: 0 auto;
}

.home-link {
  color: #555;
}

.home-link:hover {
  color: #fd150b;
  transition-duration: 0.4s;
  text-decoration: none;
}

.home-heading {
  color: #555;
}
.home-heading:hover {
  color: #555;
  text-decoration: none;
  cursor: default;
}

.home-bullets {
  line-height: 21px;
  margin-bottom: 10px;
}

.home-read-more-link {
  font-size: 1em;
  font-weight: bold;
  color: #2f2d2d;
  font-family: "open sans", san serif, garamond;
}

.home-read-more-link:hover {
  text-decoration: none;
  color: #2f2d2d;
  cursor: pointer;
}

@media (max-width: 1100px) {
  #home-body {
    margin-top: -50px;
  }
}

@media (max-width: 950px) {
  #home-body {
    margin-top: -150px;
  }
}

@media (max-width: 850px) {
  #home-body {
    margin-top: -190px;
  }
}

@media (max-width: 750px) {
  #home-body {
    margin-top: -220px;
  }
}

@media (max-width: 650px) {
  #slider-wrapper {
    min-height: 500px;
  }

  #home-body {
    margin-top: -280px;
  }
}
