#hamburger-menu {
  background: gray;
  position: absolute;
  right: 14px;
  top: 76px;
  width: 100%;
  padding-top: 20px;
  padding-right: 40px;
  padding-bottom: 20px;
  padding-left: 55px;
  transform-origin: 0% 0%;
  transform: translate(130%);
  transition: transform 400ms ease-in;
}

@media (min-width: 1000px) {
  #hamburger-menu {
    right: -200px;
  }
}

#hamburger-menu li a {
  color: #fff;
}

#hamburger-menu li a:hover {
  text-decoration: none;
  color: rgb(47, 45, 45);
}

#hamburger-menu li ul li {
  list-style-type: disc;
  padding-left: 0px;
}
