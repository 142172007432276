.understanding {
}

.understanding-text,
.commitment-text,
.excellence-text {
  position: absolute;
  top: 50%;
  left: 25px;
}

.image-text-wrapper-0 {
  z-index: 2;
  top: 45%;
  left: 40px;
  position: absolute;
}

.image-text-wrapper-1 {
  z-index: 2;
  top: 55%;
  left: 40px;
  position: absolute;
}

.image-text-wrapper-2 {
  z-index: 2;
  top: 15%;
  left: 40px;
  position: absolute;
}

@media (max-width: 1000px) {
  .image-text-wrapper-1 {
    top: 20%;
  }
  .image-text-wrapper-0 {
    top: 25%;
  }
}

@media (max-width: 850px) {
  .commitment-text {
    top: 50%;
  }
}

@media (max-width: 685px) {
  /* understanding header */
  .image-text-wrapper-0 {
    top: 15%;
    left: 10px;
  }
  /* understanding text */
  .understanding-text {
    top: 50%;
    left: 0px;
  }
  .commitment-text {
    left: 0px;
  }
  .image-overlay {
    max-width: 485px;
    width: 250px;
  }
}
/*
@media (max-width: 525px) {

  .image-text-wrapper-0 {
    top: -4%;
    left: 0px;
  }

  .understanding-text {
    top: 50%;
    left: 0px;
  }
  .image-text-wrapper-1 {
    top: 17%;
  }
  .image-overlay {
    max-width: 400px;
  }
} */
