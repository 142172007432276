.tinySliderQuotes {
  fontfamily: sans-serif;
  textalign: center;
}

.tns-ovh {
  max-width: 100% !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}
