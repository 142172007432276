html {
  overflow-y: scroll;
  overflow-x: hidden !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", San serif, garamond, gotham, Arial, Helvetica,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

a {
  transition-duration: 0.4s;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.push-top-sm {
  margin-top: 15px;
}

.push-top-lg {
  margin-top: 75px;
}

.push-top {
  margin-top: 25px;
}

.push-bottom-sm {
  margin-bottom: 15px;
}

.push-bottom {
  margin-bottom: 25px;
}

.push-bottom-lg {
  margin-bottom: 50px;
}

p {
  font-family: "Source Sans Pro", sans-serif, "Open Sans", san serif,
    adobe-garamond-pro-1, adobe-garamond-pro-2, Garamond, Times, serif;
  font-size: 16px;

  line-height: 24px;
  font-weight: 400;
  color: rgb(47, 45, 45);
}

h2 {
  font-size: 21px;
  line-height: 20px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
  color: rgb(77, 77, 77);
  font-family: arial;
}

ul {
  line-height: 30px;
}

.Attorneys-Links {
  color: rgb(85, 85, 85);
  font-family: "Open Sans", san serif, garamond;
  font-weight: 400;
  text-decoration: none;
  font-size: 16px;
  transition-duration: 0.4s;
}

.Attorneys-Links:hover {
  text-decoration: none;
  color: rgb(85, 85, 85);
}

/* @media (max-width: 992px) {
  .side-bar-hidden-lg {
    display: none;
  }
} */

@media (max-width: 768px) {
  .side-bar-hidden {
    display: none;
  }
}
