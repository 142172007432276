#contact-us .page-title {
  font-family: "Open Sans", san serif, garamond;
  font-weight: 500;
  color: #444;
  text-transform: capitalize;
  font-size: 17px;
  padding-left: 15px;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 10px;
}

#contact-us .contact-title {
  font-size: 18px;
  color: #3a4972;
  font-family: adobe-garamond-pro-1, adobe-garamond-pro-2, Garamond, Times,
    serif;
  font-weight: bold;
}

#contact-us .gray-line {
  border-bottom: 1px solid #e1e1e1;
  border-top: 0px;
  margin-top: 20px;
  padding-top: 10px;
}

#contact-us .contact-information {
  font-family: adobe-garamond-pro-1, adobe-garamond-pro-2, Garamond, Times,
    serif;
  font-weight: normal;
  margin-top: 5px;
  font-size: 18px;
  margin-bottom: 0px;
  line-height: 18px;
}

#contact-us .email-link {
  text-decoration: none;
  color: #555;
}

#contact-us .email-link:hover {
  color: #fd150b;
}
#contact-us label {
  display: block;
  margin-top: 15px;
  margin-bottom: 5px;
  font-family: "Open Sans", San serif, garamond, gotham, Arial, Helvetica,
    sans-serif;
  color: #2f2d2d;
  font-size: 1em;
}

#contact-us label::before {
  font-family: FontAwesome;
  content: "\f111";
  padding-right: 5px;
  font-size: 7px;
  vertical-align: 3px;
  color: red;
}

#contact-us #cont-form .contact-form-input {
  resize: none;
  overflow: hidden;
  min-width: 85% !important;
  height: auto;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  margin-right: 3%;
  border-color: #ebebeb;
  padding-left: 10px;
  margin-bottom: 20px;
  background-color: #ffffff;
  border: 1px solid #cecece;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border linear 0.2s, box-shadow linear 0.2s;
  -moz-transition: border linear 0.2s, box-shadow linear 0.2s;
  -o-transition: border linear 0.2s, box-shadow linear 0.2s;
  transition: border linear 0.2s, box-shadow linear 0.2s;
}

#message {
  resize: vertical;
  min-height: 120px;
}

#cont-form .submit-form {
  background: #009dcd;
  color: #fff;
  padding: 12px 31px;
  font-weight: bold;
  border: none;
  height: auto !important;
  font-size: 14px;
  min-width: 40%;
  margin-bottom: 25px;
  text-transform: uppercase;
}
#cont-form .submit-form:disabled {
  background: gray;
}

#contact-us .hidden {
  display: none;
}

#contact-us .successMessage {
  display: block;
  margin-bottom: 25px;
}
